import React from "react";
import Footer from "../organisms/Footer";
import Header from "../organisms/Header";
import styles from "../../styles/layout.module.css";
import { Box } from "@chakra-ui/react";
import { useRouter } from "next/router";
function Layout({ children, fullWidth }) {
  const currentyear = new Date().getFullYear();
  const router = useRouter();

  return (
    <Box className={styles.layout}>
      {/* borderTop={"16px solid black"} */}
      {router.route === "/" && (
        <>
          <img className={styles.leftBg} src="/new-ui/images/left-bg.png" />
          <img className={styles.rightBg} src="/new-ui/images/right-bg.png" />
          {/* small bgs */}
          <img
            className={styles.leftBgSM}
            src="/new-ui/images/small-left-bg.png"
          />
          <img
            className={styles.rightBgSM}
            src="/new-ui/images/small-right-bg.png"
          />
        </>
      )}

      {router?.route === "/" ? (
        ""
      ) : (
        <>
          <img
            className={styles.blog__top__leftBg}
            src="/new-ui/images/blog-top-left-bg.png"
          />
          <img
            className={styles.blog__top__rightBg}
            src="/new-ui/images/blog-top-right-bg.png"
          />
          <img
            className={styles.blog__top__bottomBg}
            src="/new-ui/images/blog-top-bottom-bg.png"
          />
          {/* small bgs */}
          <img
            className={styles.blog__top__rightBgSM}
            src="/new-ui/images/blog-sm-top-right-bg.png"
          />
          <img
            className={styles.blog__top__bottomBgSM}
            src="/new-ui/images/blog-sm-top-left-bg.png"
          />
        </>
      )}

      {router?.route === "/" ? (
        ""
      ) : (
        <>
          {/* for big device */}
          <img
            className={styles.blog__leftBg}
            src="/new-ui/images/blog-left-bg.png"
          />
          <img
            className={styles.blog__rightBg}
            src="/new-ui/images/blog-right-bg.png"
          />

          {/* for small device */}

          {/* right bg */}
          <img
            className={styles.blog__rightBgOne}
            src="/new-ui/images/blog-sm-right-bg-one.png"
          />
          <img
            className={styles.blog__rightBgTwo}
            src="/new-ui/images/blog-sm-right-bg-two.png"
          />
          <img
            className={styles.blog__rightBgThree}
            src="/new-ui/images/blog-sm-right-bg-three.png"
          />
          <img
            className={styles.blog__rightBgFour}
            src="/new-ui/images/blog-sm-right-bg-four.png"
          />
          <img
            className={styles.blog__rightBgFive}
            src="/new-ui/images/blog-sm-right-bg-five.png"
          />

          {/* left bg */}
          <img
            className={styles.blog__leftBgOne}
            src="/new-ui/images/blog-sm-left-bg-one.png"
          />
          <img
            className={styles.blog__leftBgTwo}
            src="/new-ui/images/blog-sm-left-bg-two.png"
          />
          <img
            className={styles.blog__leftBgThree}
            src="/new-ui/images/blog-sm-left-bg-three.png"
          />
          <img
            className={styles.blog__leftBgFour}
            src="/new-ui/images/blog-sm-left-bg-four.png"
          />
        </>
      )}
      <Box px={["0px", "20px", "30px", "50px", "50px", "210px"]} as="header">
        <Header />
      </Box>
      <Box
        px={!fullWidth && ["0px", "20px", "30px", "50px", "50px", "210px"]}
        as="main"
        className={styles.main}
      >
        {children}
      </Box>
      <Box>
        <Box px={["0px", "20px", "30px", "50px", "50px", "210px"]} as="footer">
          <Footer />
        </Box>
        <Box as="p" className={styles.copyright}>
          Copyright © {currentyear} CoverClap All rights reserved.
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
